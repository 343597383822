// src/components/Dashboard/AircraftComponents/AircraftDetails.tsx

import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import apiClient from "../../../tools/AxiosInterceptor";
import {v4 as uuidv4} from 'uuid';
import {Badge} from '../../../catalystui/badge';
import {
    Aircraft,
    Document,
    MaintenanceStatement,
    InsurancePolicy,
    CertificateOfReleaseToService, InstalledComponent
} from '../../../types';
import {Table, TableBody, TableRow, TableCell, TableHead, TableHeader} from "../../../catalystui/table";
import {Heading, Subheading} from "../../../catalystui/heading";
import {Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu} from '../../../catalystui/dropdown'
import {EllipsisHorizontalIcon} from "@heroicons/react/20/solid";
import {Button} from "../../../catalystui/button";
import CRSForm from "./CRSForm";
import {Alert, AlertActions, AlertDescription, AlertTitle} from "../../../catalystui/alert";
import MaintenanceStatementForm from "./MaintenanceStatementForm";
import {PlusIcon} from "@heroicons/react/16/solid";
import {Text} from "../../../catalystui/text";
import {Divider} from "../../../catalystui/divider";
import AddEditComponentModal from "./AddEditViewForms/AddEditComponentModal";

interface ExpiringDocument {
    registration: string;
    documentType: string;
    documentNumber?: string;
    expiryDate?: string;
    status: string;
    details: string;
    componentUuid?: string;
    scheduleDescription?: string;
}

interface AircraftDetailsProps {
    aircraft: Aircraft;
    expiringDocuments: ExpiringDocument[]
}

const AircraftDetails: React.FC<AircraftDetailsProps> = ({aircraft, expiringDocuments}) => {
    const {components, documents} = aircraft;
    const [isCRSDialogOpen, setCRSDialogOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isStatementFormOpen, setIsStatementFormOpen] = useState(false);
    const [statementError, setStatementError] = useState<string | null>(null);
    const [isStatementAlertOpen, setIsStatementAlertOpen] = useState(false);
    const [isComponentModalOpen, setIsComponentModalOpen] = useState(false);
    const [editingComponent, setEditingComponent] = useState<InstalledComponent | null>(null);
    const navigate = useNavigate();

    // Handler for saving a new or edited component
    const handleComponentSave = async (component: InstalledComponent) => {
        const token = sessionStorage.getItem('token');
        try {
            const payload = {
                aircraftUuid: aircraft.uuid,
                component,
            };

            const response = await apiClient.post(`/v1/aircraft/${aircraft.uuid}/component`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log('Component added/updated successfully:', response.data);
                // Optionally update the aircraft.components state or refresh data here

                // Close the modal and clear editing state
                setIsComponentModalOpen(false);
                setEditingComponent(null);
            } else {
                console.error('Failed to save component:', response.data);
            }
        } catch (error) {
            console.error('An error occurred while saving the component:', error);
        }
    };

    // Function to open modal for editing a component
    const handleEditComponent = (component: InstalledComponent) => {
        setEditingComponent(component);
        setIsComponentModalOpen(true);
    };

    const handleCRSSubmit = async (crsData: CertificateOfReleaseToService) => {

        // Clone crsData to avoid mutating the state directly
        const submissionData = {...crsData};

        // Format dates to ISO strings
        formatCRSDates(submissionData);

        // Generate UUID if not present
        if (!submissionData.uuid) {
            submissionData.uuid = uuidv4();
        }

        // Add submittedAt timestamp
        submissionData.submittedAt = new Date().toISOString();

        // Get the authentication token
        const token = sessionStorage.getItem('token');

        try {
            const response = await apiClient.post(`v1/aircraft/${aircraft.uuid}/crs`, submissionData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 201 || response.status === 200) {
                console.log('CRS submitted successfully:', response.data);

                // Optionally, update the aircraft data in the frontend if needed
                // For now, we can navigate or provide feedback to the user

                // Close the form
                setCRSDialogOpen(false);
                setIsAlertOpen(true);

                // Navigate to another page if needed
                navigate(`/dashboard/aircraft/${aircraft.uuid}`);
            } else {
                setError('Failed to submit CRS. Please try again.');
                console.error('Error submitting CRS:', response.data);
            }
        } catch (error: unknown) {
            setError('An error occurred while submitting the CRS.');
            console.error('An error occurred during the API call:', error);
        }
    };
    // Function to format dates in crsData
    const formatCRSDates = (data: CertificateOfReleaseToService) => {
        // Format the dates in the main CRS data
        data.maintenance.started = formatDateToISO(data.maintenance.started);
        data.maintenance.finished = formatDateToISO(data.maintenance.finished);
        data.releaseToService.date = formatDateToISO(data.releaseToService.date);

        // Format dates in linked maintenance tasks
        data.linkedMaintenanceTasks.forEach((task) => {
            task.performedDate = formatDateToISO(task.performedDate);
        });

        // Format dates in ad-hoc tasks
        data.adHocTasks.forEach((task) => {
            task.performedDate = formatDateToISO(task.performedDate);
        });
    };
    const handleStatementSubmit = async (statementData: MaintenanceStatement) => {
        // Clone data to avoid mutating state directly
        const submissionData = {...statementData};

        // Generate UUID if not present
        if (!submissionData.uuid) {
            submissionData.uuid = uuidv4();
        }

        // Format dates to ISO strings
        submissionData.returnToServiceDate = formatDateToISO(submissionData.returnToServiceDate);
        submissionData.validTillDate = formatDateToISO(submissionData.validTillDate);

        // Prepare payload for backend
        const payload = {
            aircraftUuid: aircraft.uuid,
            document: submissionData,
        };

        // Get the authentication token
        const token = sessionStorage.getItem('token');

        try {
            const response = await apiClient.post(`/v1/aircraft/${aircraft.uuid}/document`, payload, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log('Maintenance Statement submitted successfully:', response.data);

                // Close the form
                setIsStatementFormOpen(false);

                // Open the Alert
                setIsStatementAlertOpen(true);

                // Optionally, refresh data or navigate
            } else {
                setStatementError('Failed to submit Maintenance Statement. Please try again.');
                console.error('Error submitting Maintenance Statement:', response.data);
                console.log(payload)
            }
        } catch (error: unknown) {
            setStatementError('An error occurred while submitting the Maintenance Statement.');
            console.error('An error occurred during the API call:', error);
        }
    };

    // Helper function to format a date string to ISO format
    const formatDateToISO = (dateString: string): string => {
        if (dateString) {
            const date = new Date(dateString);
            if (!isNaN(date.getTime())) {
                return date.toISOString();
            } else {
                // Handle invalid date
                console.warn(`Invalid date: ${dateString}`);
                return '';
            }
        }
        return '';
    };
    const formatDate = (isoDate: string): string => {
        const date = new Date(isoDate);
        return date.toLocaleDateString('en-UK', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };
    const getComponentBadge = (componentUuid: string) => {
        const matchedDocument = expiringDocuments.find(doc => doc.componentUuid === componentUuid);

        if (matchedDocument) {
            return (
                <Badge color="orange">
                    {matchedDocument.scheduleDescription}: {matchedDocument.status}
                </Badge>
            );
        }
        return <Badge color="green">All Good</Badge>;
    };

    return (
        <div className="mx-auto">
            <div className="pb-4">
                <div className="lg:flex lg:items-center lg:justify-between">
                    <div className="min-w-0 flex-1">
                        <Heading>{aircraft.registration}</Heading>
                        <Subheading>{aircraft.manufacturer} {aircraft.model}</Subheading>
                        <Badge color="sky">{aircraft.mthCounter} MTH</Badge>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">

            <span className="sm:block sm:ml-3">
              <Button href={`/dashboard/aircraft/${aircraft.uuid}/issues`}>
                Issues
              </Button>
            </span>
                    </div>
                </div>
            </div>

            <CRSForm
                onSubmit={handleCRSSubmit}
                aircraft={aircraft}
                isOpen={isCRSDialogOpen}
                onClose={() => setCRSDialogOpen(false)}
            />
            <MaintenanceStatementForm
                onSubmit={handleStatementSubmit}
                aircraft={aircraft}
                isOpen={isStatementFormOpen}
                onClose={() => setIsStatementFormOpen(false)}
            />
            {isStatementAlertOpen && (
                <Alert open={isStatementAlertOpen} onClose={() => setIsStatementAlertOpen(false)}>
                    <AlertTitle>Maintenance Statement submitted successfully.</AlertTitle>
                    <AlertDescription>
                        The document has been added to the aircraft's records.
                    </AlertDescription>
                    <AlertActions>
                        <Button onClick={() => setIsStatementAlertOpen(false)}>OK</Button>
                    </AlertActions>
                </Alert>
            )}

            {/* Alert Component */}
            {isAlertOpen && (
                <Alert open={isAlertOpen} onClose={() => setIsAlertOpen(false)}>
                    <AlertTitle>CRS submitted successfully.</AlertTitle>
                    <AlertDescription>
                        Maintenance history and documents associated with aircraft have been updated.
                    </AlertDescription>
                    <AlertActions>
                        <Button onClick={() => setIsAlertOpen(false)}>OK</Button>
                    </AlertActions>
                </Alert>
            )}

            {documents && documents.length > 0 ? (
                <>
                    <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
                        {documents.map((doc: Document | MaintenanceStatement | InsurancePolicy | CertificateOfReleaseToService, index) => {
                            if (doc.documentType === "Maintenance Statement" && "crsNumber" in doc) {
                                // Render Maintenance Statement details
                                const maintenanceDoc = doc as MaintenanceStatement;
                                return (
                                    <div key={index}>
                                        <hr className="w-full border-t border-zinc-950/10 dark:border-white/10"/>
                                        <div className="mt-2 flex items-center justify-between">
                                            <div className="min-w-0 flex-1">
                                                <Subheading>
                                                    {maintenanceDoc.documentType} {maintenanceDoc.documentNumber}
                                                </Subheading>
                                            </div>
                                            <div className="flex lg:ml-4 lg:mt-0">
        <span className="sm:block sm:ml-3">
            <Dropdown>
                <DropdownButton plain>
                    <EllipsisHorizontalIcon className="h-5 w-5"/>
                </DropdownButton>
                <DropdownMenu>
                    <DropdownItem onClick={() => setIsStatementFormOpen(true)}>
  <DropdownLabel>Add MS</DropdownLabel>
</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </span>
                                            </div>
                                        </div>
                                        <div className="mt-6 text-s font-s text-gray-900 dark:text-gray-100">
                                            Next service
                                        </div>
                                        <div
                                            className="mt-1 text-2xl font-semibold text-gray-900 dark:text-gray-100 flex items-baseline">
                                            {maintenanceDoc.validTillMTH} MTH
                                            <span className="ml-1 text-md font-normal text-gray-900 dark:text-gray-100"
                                                  style={{fontSize: '0.8rem'}}>
                                ±{maintenanceDoc.validTillMTHTolerance} MTH
                            </span>
                                        </div>
                                        <div
                                            className="mt-1 text-2xl font-semibold text-gray-900 dark:text-gray-100 flex items-baseline">
                                            {formatDate(maintenanceDoc.validTillDate)}
                                            <span className="ml-1 text-md font-normal text-gray-900 dark:text-gray-100"
                                                  style={{fontSize: '0.8rem'}}>
                                ±{maintenanceDoc.validTillDateDaysTolerance} days
                            </span>
                                        </div>
                                        <div className="mt-3 text-sm space-y-2">
                                            {/* MTH Counter Badge */}
                                            {(() => {
                                                const mthDifference = maintenanceDoc.validTillMTH - aircraft.mthCounter;
                                                let mthBadgeColor: 'yellow' | 'orange' | 'red' | 'lime' = 'lime';
                                                if (mthDifference < 0) {
                                                    mthBadgeColor = 'red';
                                                } else if (mthDifference <= maintenanceDoc.validTillMTHTolerance) {
                                                    mthBadgeColor = 'orange';
                                                } else if (mthDifference < 10) {
                                                    mthBadgeColor = 'yellow';
                                                }
                                                return (
                                                    <div>
                                                        <Badge color={mthBadgeColor}>
                                                            MTH Remaining: {mthDifference.toFixed(1)} MTH
                                                        </Badge>
                                                    </div>
                                                );
                                            })()}

                                            {/* Date Badge */}
                                            {(() => {
                                                const currentDate = new Date();
                                                const validTillDate = new Date(maintenanceDoc.validTillDate);
                                                const daysToExpiry = (validTillDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);
                                                let dateBadgeColor: 'yellow' | 'orange' | 'red' | 'lime' = 'lime';
                                                if (daysToExpiry < 0) {
                                                    dateBadgeColor = 'red';
                                                } else if (daysToExpiry <= maintenanceDoc.validTillDateDaysTolerance) {
                                                    dateBadgeColor = 'orange';
                                                } else if (daysToExpiry < 10) {
                                                    dateBadgeColor = 'yellow';
                                                }
                                                return (
                                                    <div>
                                                        <Badge color={dateBadgeColor}>
                                                            Days to Expiry: {Math.ceil(daysToExpiry)}
                                                        </Badge>
                                                    </div>
                                                );
                                            })()}
                                        </div>
                                    </div>
                                );
                            } else if (doc.documentType === "Insurance Policy" && "validFromDate" in doc) {
                                // Render Insurance Policy details
                                const insuranceDoc = doc as InsurancePolicy;
                                return (
                                    <div key={index}
                                         className="w-full border-t border-zinc-950/10 dark:border-white/10">
                                        <div className="mt-2 flex items-center justify-between">
                                            <div className="min-w-0 flex-1">
                                                <Subheading>
                                                    {insuranceDoc.documentType} {insuranceDoc.documentNumber}
                                                </Subheading>
                                            </div>
                                            <div className="flex lg:ml-4 lg:mt-0">
        <span className="sm:block sm:ml-3">
            <Dropdown>
                <DropdownButton plain>
                    <EllipsisHorizontalIcon className="h-5 w-5"/>
                </DropdownButton>
                <DropdownMenu>
                    <DropdownItem>
  <DropdownLabel>Add Insurance</DropdownLabel>
</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </span>
                                            </div>
                                        </div>
                                        <div className="mt-6 text-s font-s text-gray-900 dark:text-gray-100">
                                            Valid Till
                                        </div>
                                        <div
                                            className="mt-1 text-2xl font-semibold text-gray-900 dark:text-gray-100 flex items-baseline">
                                            {formatDate(insuranceDoc.validTillDate)}
                                        </div>

                                        <div className="mt-3 text-xs text-gray-700 dark:text-gray-300">
                                            Territorial Scope: {insuranceDoc.territorialScopeOfFlights}
                                        </div>
                                        <div className="mt-3 text-xs text-gray-700 dark:text-gray-300">
                                            Type of Insured Flights: {insuranceDoc.typeOfInsuredFlights}
                                        </div>
                                        <div className="mt-3 text-sm space-y-2">
                                            {/* Date Badge */}
                                            {(() => {
                                                const currentDate = new Date();
                                                const validTillDate = new Date(insuranceDoc.validTillDate);
                                                const daysToExpiry = (validTillDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);
                                                let dateBadgeColor: 'yellow' | 'orange' | 'red' | 'lime' = 'lime';
                                                if (daysToExpiry < 0) {
                                                    dateBadgeColor = 'red';
                                                } else if (daysToExpiry <= 10) {
                                                    dateBadgeColor = 'orange';
                                                } else if (daysToExpiry < 30) {
                                                    dateBadgeColor = 'yellow';
                                                }
                                                return (
                                                    <div>
                                                        <Badge color={dateBadgeColor}>
                                                            Days to Expiry: {Math.ceil(daysToExpiry)}
                                                        </Badge>
                                                    </div>
                                                );
                                            })()}
                                        </div>
                                    </div>
                                );
                            } else if (doc.documentType === "Certificate of Release to Service" && "maintenanceTasksPerformed" in doc) {
                                const crsDoc = doc as CertificateOfReleaseToService;
                                return (
                                    <div key={index}
                                         className="w-full border-t border-zinc-950/10 dark:border-white/10">
                                        <div className="mt-2 flex items-center justify-between">
                                            <div className="min-w-0 flex-1">
                                                <Subheading>
                                                    {crsDoc.documentType} {crsDoc.documentNumber}
                                                </Subheading>
                                            </div>
                                            <div className="flex lg:ml-4 lg:mt-0">
        <span className="sm:block sm:ml-3">
            <Dropdown>
                <DropdownButton plain>
                    <EllipsisHorizontalIcon className="h-5 w-5"/>
                </DropdownButton>
                <DropdownMenu>
                    <DropdownItem onClick={() => setCRSDialogOpen(true)}>
  <DropdownLabel>Add CRS</DropdownLabel>
</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </span>
                                            </div>
                                        </div>

                                        {/* Maintenance Tasks Performed */}
                                        <div className="mt-6">
                                            <h4 className="text-sm font-semibold text-gray-900 dark:text-gray-100">Maintenance
                                                Tasks Performed</h4>
                                            <ul className="mt-2 text-xs space-y-1 text-gray-700 dark:text-gray-300">
                                                {crsDoc.maintenanceTasksPerformed.map((task, idx) => (
                                                    <li key={idx}>
                                                        <span
                                                            className="font-semibold">{task.item}:</span> {task.comment}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        {/* Parts Removed */}
                                        <div className="mt-4">
                                            <h4 className="text-sm font-semibold text-gray-900 dark:text-gray-100">Parts
                                                Removed</h4>
                                            <ul className="mt-2 text-xs space-y-1 text-gray-700 dark:text-gray-300">
                                                {crsDoc.partsRemoved.map((part, idx) => (
                                                    <li key={idx}>
                                                        <span className="font-semibold">{part.description}</span> -
                                                        P/N: {part.partNumber}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        {/* Parts Installed */}
                                        <div className="mt-4">
                                            <h4 className="text-sm font-semibold text-gray-900 dark:text-gray-100">Parts
                                                Installed</h4>
                                            <ul className="mt-2 text-xs space-y-1 text-gray-700 dark:text-gray-300">
                                                {crsDoc.partsInstalled.map((part, idx) => (
                                                    <li key={idx}>
                                                        <span className="font-semibold">{part.description}</span> -
                                                        P/N: {part.partNumber}, EASA Form 1: {part.easaForm}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        {/* Badge for Release to Service */}
                                        <div className="mt-4 text-xs">
                                            <Badge color="lime">
                                                Released
                                                on {formatDate(crsDoc.releaseToService.date)} by {crsDoc.releaseToService.certifyingStaff}
                                            </Badge>
                                        </div>
                                    </div>
                                );
                            } else {
                                // Generic display for other document types
                                return (
                                    <div key={index} className="p-4 border rounded-lg shadow bg-white dark:bg-gray-800">
                                        <hr className="w-full border-t border-zinc-950/10 dark:border-white/10"/>
                                        <div className="mt-6 text-lg font-medium text-gray-900 dark:text-gray-100">
                                            {doc.documentType} {doc.documentNumber}
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </>
            ) : (
                <div className="text-center">
                    <svg
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        className="mx-auto size-12 text-gray-400"
                    >
                        <path
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                            strokeWidth={2}
                            vectorEffect="non-scaling-stroke"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <Subheading className="mt-2 text-sm font-semibold text-gray-900">No documents</Subheading>
                    <Text>No documents found for this aircraft.</Text>
                    <div className="mt-6">
                        <Button onClick={() => setIsStatementFormOpen(true)}>
                            <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5"/>
                            New Maintenance Statement
                        </Button>
                    </div>
                </div>
            )}


            {/* Installed Components */}
            {components && components.length > 0 ? (
                <div className="mt-8">
                    <div className="pb-4">
                        <div className="lg:flex lg:items-center lg:justify-between">
                            <div className="min-w-0 flex-1">
                                <Heading>Components</Heading>
                            </div>
                            <div className="mt-5 flex lg:ml-4 lg:mt-0">

            <span className="sm:block sm:ml-3">
              <Button onClick={() => setIsComponentModalOpen(true)}>
                Add Component
              </Button>
            </span>
                            </div>
                        </div>
                    </div>
                    <Heading>

                    </Heading>
                    <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]" dense>
                        <TableHead>
                            <TableRow>
                                <TableHeader className="text-xs">Title</TableHeader>
                                <TableHeader className="hidden text-xs sm:table-cell">Serial Number</TableHeader>
                                <TableHeader className="text-xs">Current Time</TableHeader>
                                <TableHeader className="text-xs">Status</TableHeader>
                                <TableHeader className="relative w-0">
                                    <span className="sr-only">Actions</span>
                                </TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {components.map((component) => (
                                <TableRow key={component.uuid}
                                          href={`/dashboard/aircraft/${aircraft.uuid}/component/${component.uuid}`}>
                                    <TableCell
                                        className="font-medium text-xs">{component.title} {component.partNumber}</TableCell>
                                    <TableCell
                                        className="hidden text-xs sm:table-cell">{component.serialNumber}</TableCell>
                                    <TableCell className="text-zinc-500 text-xs">{component.currentHours}</TableCell>

                                    {/* Conditionally render the badge */}
                                    <TableCell className="text-xs">
                                        {getComponentBadge(component.uuid)}
                                    </TableCell>

                                    <TableCell>
                                        <div>
                                            <Dropdown>
                                                <DropdownButton plain aria-label="More options">
                                                    <EllipsisHorizontalIcon/>
                                                </DropdownButton>
                                                <DropdownMenu anchor="bottom end">
                                                    {/*<DropdownItem>View</DropdownItem>*/}
                                                    <DropdownItem
                                                        onClick={() => handleEditComponent(component)}>Edit</DropdownItem>
                                                    {/*<DropdownItem>Delete</DropdownItem>*/}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            ) : (
                <div>
                    <Divider className="my-8"/>
                    <div className="text-center">
                        <svg
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            className="mx-auto size-12 text-gray-400"
                        >
                            <path
                                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                strokeWidth={2}
                                vectorEffect="non-scaling-stroke"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <Subheading className="mt-2 text-sm font-semibold text-gray-900">No components</Subheading>
                        <Text>No installed components for this aircraft.</Text>
                        <div className="mt-6">
                            <Button onClick={() => setIsComponentModalOpen(true)}>
                                <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5"/>
                                New Component
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {/* Render the Add/Edit Component Modal */}
            <AddEditComponentModal
                open={isComponentModalOpen}
                onClose={() => {
                    setIsComponentModalOpen(false);
                    setEditingComponent(null);
                }}
                onSave={handleComponentSave}
                initialData={editingComponent || undefined}
            />
        </div>
    );
};
export default AircraftDetails;


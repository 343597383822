// src/components/Dashboard/AircraftComponents/AddEditComponentModal.tsx
import React, { useState, useEffect } from 'react';
import { InstalledComponent } from '../../../../types';
import { v4 as uuidv4 } from 'uuid';
import { Input } from '../../../../catalystui/input';
import { Button } from '../../../../catalystui/button';
import {
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from '../../../../catalystui/fieldset';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogDescription,
    DialogTitle,
} from '../../../../catalystui/dialog';
import { XCircleIcon } from '@heroicons/react/20/solid';

interface AddEditComponentModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (component: InstalledComponent) => void;
    /** If editing an existing component, pass its data here */
    initialData?: InstalledComponent;
}

const AddEditComponentModal: React.FC<AddEditComponentModalProps> = ({
                                                                         open,
                                                                         onClose,
                                                                         onSave,
                                                                         initialData,
                                                                     }) => {
    const [error, setError] = useState<string | null>(null);
    const [componentData, setComponentData] = useState<InstalledComponent>({
        uuid: '',
        title: '',
        partNumber: '',
        serialNumber: '',
        currentHours: 0,
        maintenanceSchedule: [],
        maintenanceHistory: [],
        adsbCompliance: [],
        maintenanceDetails: undefined,
    });

    useEffect(() => {
        if (open) {
            if (initialData) {
                setComponentData(initialData);
            } else {
                setComponentData({
                    uuid: '',
                    title: '',
                    partNumber: '',
                    serialNumber: '',
                    currentHours: 0,
                    maintenanceSchedule: [],
                    maintenanceHistory: [],
                    adsbCompliance: [],
                    maintenanceDetails: undefined,
                });
            }
        }
    }, [open, initialData]);

    const handleChange = (field: keyof InstalledComponent, value: any) => {
        setComponentData((prevData) => ({
            ...prevData,
            [field]: field === 'currentHours' ? Number(value) : value,
        }));
    };

    const validateData = (): boolean => {
        setError(null);

        if (!componentData.title.trim()) {
            setError('Title is required.');
            return false;
        }
        if (!componentData.partNumber.trim()) {
            setError('Part Number is required.');
            return false;
        }
        if (!componentData.serialNumber.trim()) {
            setError('Serial Number is required.');
            return false;
        }
        if (isNaN(componentData.currentHours)) {
            setError('Current Hours must be a valid number.');
            return false;
        }

        return true;
    };

    const handleSave = () => {
        if (!validateData()) {
            return;
        }
        if (!componentData.uuid) {
            componentData.uuid = uuidv4();
        }
        setError(null);
        onSave(componentData);
    };

    if (!open) return null;

    return (
        <Dialog size="5xl" open={open} onClose={onClose}>
            <DialogTitle>
                {initialData ? 'Edit Component' : 'Add New Component'}
            </DialogTitle>
            <DialogDescription>
                Fill out the details for the component.
            </DialogDescription>

            {/* Display an error message if there is one */}
            {error && (
                <div className="rounded-md my-2 bg-red-50 dark:bg-red-800 p-2">
                    <div className="flex">
                        <div className="shrink-0">
                            <XCircleIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-red-400 dark:text-red-100"
                            />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800 dark:text-red-100">
                                {error}
                            </h3>
                        </div>
                    </div>
                </div>
            )}

            <DialogBody>
                <Fieldset>
                    <FieldGroup>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <Field>
                                <Label>Title</Label>
                                <Input
                                    name="title"
                                    value={componentData.title}
                                    onChange={(e) => handleChange('title', e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>Part Number</Label>
                                <Input
                                    name="partNumber"
                                    value={componentData.partNumber}
                                    onChange={(e) => handleChange('partNumber', e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>Serial Number</Label>
                                <Input
                                    name="serialNumber"
                                    value={componentData.serialNumber}
                                    onChange={(e) => handleChange('serialNumber', e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>Current Hours</Label>
                                <Input
                                    type="number"
                                    name="currentHours"
                                    value={componentData.currentHours}
                                    onChange={(e) =>
                                        handleChange('currentHours', e.target.value)
                                    }
                                />
                            </Field>
                        </div>
                    </FieldGroup>
                </Fieldset>
            </DialogBody>
            <DialogActions>
                <Button plain onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddEditComponentModal;

import React, {useEffect, useState} from 'react';
import apiClient from "../../../tools/AxiosInterceptor";
import {v4 as uuidv4} from 'uuid';
import {Aircraft, Flight, User} from "../../../types"
import {useNavigate} from 'react-router-dom';
import {Button} from "../../../catalystui/button";
import GeneralFlightInfoSection from './GeneralFlightInfoSection';
import EngineOilFuelSection from "./EngineOilFuelSection";
import ExtraInformationSection from './ExtraInformationSection';
import usePilots from '../../../hooks/usePilots';
import useAircrafts from '../../../hooks/useAircrafts';
import { validateEngineTime } from './validation';

interface AddFlightSectionProps {
    flightData?: Flight; // Make it optional as it may not always be provided
}

const AddFlightSection: React.FC<AddFlightSectionProps> = ({flightData}) => {
    const navigate = useNavigate();
    const today = new Date().toISOString().split('T')[0];
    const selectedOrg = sessionStorage.getItem('selectedOrg');
    const selectedOrgId = selectedOrg ? JSON.parse(selectedOrg).id : '';
    const [error, setError] = useState<string | null>(null);

    const formatDateForInput = (dateString: string) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];  // Get 'YYYY-MM-DD' format
    };

    const [formValues, setFormValues] = useState<Flight>({
        aircraft_registration: flightData ? flightData.aircraft_registration : '',
        flight_date: flightData ? formatDateForInput(flightData.flight_date) : today,
        departure_airfield: flightData?.departure_airfield || '',
        arrival_airfield: flightData?.arrival_airfield || '',
        pilot_in_command: flightData?.pilot_in_command || '',
        license_number: flightData?.license_number || '',
        departure_time: flightData?.departure_time || '00:00',
        arrival_time: flightData?.arrival_time || '00:00',
        flight_time: flightData?.flight_time || '00:00',
        ground_time: flightData?.ground_time || '00:00',
        number_of_landings: flightData?.number_of_landings || 1,
        engine_time_before_flight: flightData?.engine_time_before_flight || 0,
        engine_time_after_flight: flightData?.engine_time_after_flight || 0,
        engine_time_difference: flightData?.engine_time_difference || 0,
        oil_on_inspection: flightData?.oil_on_inspection || 0,
        oil_added: flightData?.oil_added || 0,
        oil_on_departure: flightData?.oil_on_departure || 0,
        fuel_on_inspection: flightData?.fuel_on_inspection || 0,
        fuel_added: flightData?.fuel_added || 0,
        fuel_on_departure: flightData?.fuel_on_departure || 0,
        fuel_on_landing: flightData?.fuel_on_landing || 0,
        fuel_used: flightData?.fuel_used || 0,
        submittedAt: flightData?.submittedAt || '',
        uuid: flightData?.uuid || '',
        pic_user_uuid: flightData?.pic_user_uuid || '',
        remarks: flightData?.remarks || '',
        organizationId: flightData?.organizationId || selectedOrgId,
        images: flightData?.images || [],
    });

    const { pilots, error: pilotsError } = usePilots();
    const { aircrafts, error: aircraftsError } = useAircrafts();

    const [pilotQuery, setPilotQuery] = useState('');
    const [aircraftQuery, setAircraftQuery] = useState('');
    const [selectedPilot, setSelectedPilot] = useState<User | null>(null);
    const [selectedAircraft, setSelectedAircraft] = useState<Aircraft | null>(null);
    const [validationError, setValidationError] = useState<string | null>(null);

    // Handle errors
    useEffect(() => {
        if (pilotsError || aircraftsError) {
            setError(pilotsError || aircraftsError);
        }
    }, [pilotsError, aircraftsError]);

    // CHANGED: Only auto-set the pilot to the current user if we are ADDING a new flight (no flightData)
    useEffect(() => {
        if (!flightData && pilots.length > 0 && !selectedPilot) {
            const currentUserUUID = sessionStorage.getItem('user_uuid');
            const currentUser = pilots.find((user) => user.uuid === currentUserUUID);
            if (currentUser) {
                setSelectedPilot(currentUser);
                setFormValues((prevValues) => ({
                    ...prevValues,
                    pilot_in_command: `${currentUser.first_name} ${currentUser.last_name}`,
                    license_number: currentUser.pilot?.pilot_license_number || '',
                    pic_user_uuid: currentUser.uuid,
                }));
            }
        }
    }, [flightData, pilots, selectedPilot]);

    // CHANGED: Wrap "fetchFlightsForAircraft" and defaulting logic in a condition that checks !flightData
    useEffect(() => {
        const fetchFlightsForAircraft = async (registration: string) => {
            try {
                const response = await apiClient.get('/getFlights', {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                } as any);

                // Filter flights for the selected aircraft
                const flightsForAircraft = response.data.filter(
                    (flight: Flight) => flight.aircraft_registration === registration
                );

                if (flightsForAircraft.length === 0) return;

                // Find the "latest" flight by comparing date/time
                const latestFlight = flightsForAircraft.reduce((latest: Flight, flight: Flight) => {
                    const latestFlightDatePart = latest.flight_date.split('T')[0];
                    const flightDatePart = flight.flight_date.split('T')[0];

                    const latestFlightDateTime = new Date(`${latestFlightDatePart}T${latest.arrival_time || '00:00'}`);
                    const flightDateTime = new Date(`${flightDatePart}T${flight.arrival_time || '00:00'}`);

                    if (isNaN(latestFlightDateTime.getTime())) return flight;
                    if (isNaN(flightDateTime.getTime())) return latest;

                    return latestFlightDateTime < flightDateTime ? flight : latest;
                }, flightsForAircraft[0]);

                if (latestFlight) {
                    // Pre-fill engine, oil, and fuel based on the last flight
                    setFormValues((prevValues) => ({
                        ...prevValues,
                        engine_time_before_flight: latestFlight.engine_time_after_flight || 0,
                        oil_on_inspection: latestFlight.oil_on_departure || 0,
                        fuel_on_inspection: latestFlight.fuel_on_landing || 0,
                    }));

                    // Another setFormValues to check if not already set
                    setFormValues((prevValues) => {
                        if (
                            !prevValues.engine_time_before_flight &&
                            !prevValues.oil_on_inspection &&
                            !prevValues.fuel_on_inspection
                        ) {
                            return {
                                ...prevValues,
                                engine_time_before_flight: latestFlight.engine_time_after_flight || 0,
                                oil_on_inspection: latestFlight.oil_on_departure || 0,
                                fuel_on_inspection: latestFlight.fuel_on_landing || 0,
                            };
                        }
                        return prevValues;
                    });
                }
            } catch (err: unknown) {
                if (err instanceof Error) {
                    setError(err.message);
                    console.log(error);
                } else {
                    setError("An unknown error occurred");
                    console.log(error);
                }
            }
        };

        // CHANGED: Only do this if we are ADDING a flight
        if (!flightData) {
            if (selectedAircraft) {
                fetchFlightsForAircraft(selectedAircraft.registration);
                setFormValues((prevValues) => ({
                    ...prevValues,
                    aircraft_registration: selectedAircraft.registration || '',
                }));
            }
            if (selectedPilot) {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    license_number: selectedPilot.pilot?.pilot_license_number || '',
                    pilot_in_command: `${selectedPilot.first_name} ${selectedPilot.last_name}`,
                    pic_user_uuid: selectedPilot.uuid,
                }));
            }
        }
    }, [flightData, selectedAircraft, selectedPilot]);

    // Engine, Oil, and Fuel calculations
    useEffect(() => {
        function safelyParseFloat(value: string | number): number {
            return typeof value === 'string' ? parseFloat(value) : value;
        }

        const engineTimeBefore = safelyParseFloat(formValues.engine_time_before_flight);
        const engineTimeAfter = safelyParseFloat(formValues.engine_time_after_flight);
        const oilInspection = safelyParseFloat(formValues.oil_on_inspection);
        const oilAdded = safelyParseFloat(formValues.oil_added);
        const fuelInspection = safelyParseFloat(formValues.fuel_on_inspection);
        const fuelAdded = safelyParseFloat(formValues.fuel_added);
        const fuelDeparture = safelyParseFloat(formValues.fuel_on_departure);
        const fuelLanding = safelyParseFloat(formValues.fuel_on_landing);

        let updates: Partial<Flight> = {};

        // Engine Time difference
        if (!isNaN(engineTimeBefore) && !isNaN(engineTimeAfter)) {
            updates.engine_time_difference = parseFloat(
                (engineTimeAfter - engineTimeBefore).toFixed(2)
            );
        }

        // Oil on departure
        if (!isNaN(oilInspection) && !isNaN(oilAdded)) {
            updates.oil_on_departure = parseFloat((oilInspection + oilAdded).toFixed(1));
        }

        // Fuel on departure
        if (!isNaN(fuelInspection) && !isNaN(fuelAdded)) {
            updates.fuel_on_departure = parseFloat((fuelInspection + fuelAdded).toFixed(0));
        }

        // Fuel used
        if (!isNaN(fuelDeparture) && !isNaN(fuelLanding)) {
            updates.fuel_used = Math.max(
                parseFloat((fuelDeparture - fuelLanding).toFixed(1)),
                0
            );
        }

        // Flight time (HH:MM)
        if (
            formValues.departure_time &&
            formValues.arrival_time &&
            formValues.ground_time
        ) {
            const [departureHours, departureMinutes] = formValues.departure_time
                .split(':')
                .map(Number);
            const [arrivalHours, arrivalMinutes] = formValues.arrival_time
                .split(':')
                .map(Number);
            const [groundHours, groundMinutes] = formValues.ground_time
                .split(':')
                .map(Number);

            if (
                !isNaN(departureHours) &&
                !isNaN(departureMinutes) &&
                !isNaN(arrivalHours) &&
                !isNaN(arrivalMinutes) &&
                !isNaN(groundHours) &&
                !isNaN(groundMinutes)
            ) {
                const departureDate = new Date(0, 0, 0, departureHours, departureMinutes);
                const arrivalDate = new Date(0, 0, 0, arrivalHours, arrivalMinutes);
                const groundTimeTotalMinutes = groundHours * 60 + groundMinutes;

                let flightTimeMinutes =
                    (arrivalDate.getTime() - departureDate.getTime()) / (1000 * 60) -
                    groundTimeTotalMinutes;

                flightTimeMinutes = Math.max(flightTimeMinutes, 0);

                const flightHours = Math.floor(flightTimeMinutes / 60);
                const flightMins = Math.floor(flightTimeMinutes % 60);
                updates.flight_time = `${flightHours.toString().padStart(2, '0')}:${flightMins
                    .toString()
                    .padStart(2, '0')}`;
            }
        }

        // Apply updates
        if (Object.keys(updates).length > 0) {
            setFormValues((prevValues) => ({
                ...prevValues,
                ...updates,
            }));
        }
    }, [
        formValues.engine_time_before_flight,
        formValues.engine_time_after_flight,
        formValues.oil_on_inspection,
        formValues.oil_added,
        formValues.fuel_on_inspection,
        formValues.fuel_added,
        formValues.fuel_on_departure,
        formValues.fuel_on_landing,
        formValues.departure_time,
        formValues.arrival_time,
        formValues.ground_time,
    ]);

    // NEW: Calculate the MTH after flight based on the flight time
    useEffect(() => {
        // Only calculate if flight_time is set and not the default "00:00", and if we have a selected aircraft.
        if (
            formValues.flight_time &&
            formValues.flight_time !== '00:00' &&
            selectedAircraft &&
            typeof selectedAircraft.mthCounter === 'number'
        ) {
            console.log(formValues.flight_time)
            // Convert flight_time (HH:MM) to decimal hours
            const [flightHoursStr, flightMinutesStr] = formValues.flight_time.split(':');
            const flightHours = parseInt(flightHoursStr, 10);
            const flightMinutes = parseInt(flightMinutesStr, 10);
            const flightTimeDecimal = flightHours + flightMinutes / 60;

            // Calculate MTH after by adding the flight time to the aircraft's current MTH counter
            // const mthAfter = selectedAircraft.mthCounter + flightTimeDecimal;
            const mthAfter = formValues.engine_time_before_flight + flightTimeDecimal;

            // Update the formValues with mth_after, rounding to 2 decimal places
            setFormValues((prevValues) => ({
                ...prevValues,
                engine_time_after_flight: parseFloat(mthAfter.toFixed(2)),
            }));
        }
    }, [formValues.flight_time, selectedAircraft]);

    // Populate form values if we have flightData (Editing scenario)
    // CHANGED: This will only run if flightData is actually provided, preventing overwriting from latest flight
    useEffect(() => {
        if (flightData && pilots.length > 0 && aircrafts.length > 0) {
            // Use existing flight data for editing
            setFormValues({
                aircraft_registration: flightData.aircraft_registration || '',
                flight_date: formatDateForInput(flightData.flight_date) || today,
                departure_airfield: flightData.departure_airfield || '',
                arrival_airfield: flightData.arrival_airfield || '',
                pilot_in_command: flightData.pilot_in_command || '',
                license_number: flightData.license_number || '',
                departure_time: flightData.departure_time || '',
                arrival_time: flightData.arrival_time || '',
                flight_time: flightData.flight_time || '',
                ground_time: flightData.ground_time || '',
                number_of_landings: flightData.number_of_landings || 0,
                engine_time_before_flight: flightData.engine_time_before_flight || 0,
                engine_time_after_flight: flightData.engine_time_after_flight || 0,
                engine_time_difference: flightData.engine_time_difference || 0,
                oil_on_inspection: flightData.oil_on_inspection || 0,
                oil_added: flightData.oil_added || 0,
                oil_on_departure: flightData.oil_on_departure || 0,
                fuel_on_inspection: flightData.fuel_on_inspection || 0,
                fuel_added: flightData.fuel_added || 0,
                fuel_on_departure: flightData.fuel_on_departure || 0,
                fuel_on_landing: flightData.fuel_on_landing || 0,
                fuel_used: flightData.fuel_used || 0,
                submittedAt: flightData.submittedAt || '',
                uuid: flightData.uuid || '',
                pic_user_uuid: flightData.pic_user_uuid || '',
                remarks: flightData.remarks || '',
                organizationId: flightData.organizationId || '',
            });

            // Find the pilot in the existing list
            const pilot = pilots.find((p) => p.uuid === flightData.pic_user_uuid);
            if (pilot) {
                setSelectedPilot(pilot);
            } else {
                // If not found, create a minimal pilot object
                const [first_name, last_name] = flightData.pilot_in_command.split(' ');
                setSelectedPilot({
                    uuid: flightData.pic_user_uuid || '',
                    first_name: first_name || '',
                    last_name: last_name || '',
                    username: '',
                    email: '',
                    role: '',
                    organizationIds: [],
                    imageUrl: '',
                    pilot: {
                        pilot_license_number: flightData.license_number || '',
                        medical: {
                            medicalClass: '',
                            validTill: '',
                        },
                        ratings: [],
                    },
                });
            }

            // Find the aircraft
            const aircraft = aircrafts.find(
                (a) => a.registration === flightData.aircraft_registration
            );
            if (aircraft) {
                setSelectedAircraft(aircraft);
            } else {
                setSelectedAircraft({
                    uuid: '',
                    registration: flightData.aircraft_registration || '',
                    organizationId: '',
                    manufacturer: '',
                    model: '',
                    mthCounter: 0,
                    components: [],
                });
            }
        }
    }, [flightData, pilots, aircrafts]);

    // Handle input changes
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        const floatValueFields = [
            "engine_time_before_flight",
            "engine_time_after_flight",
            "engine_time_difference",
            "oil_on_inspection",
            "oil_added",
            "oil_on_departure",
            "fuel_on_inspection",
            "fuel_added",
            "fuel_on_departure",
            "fuel_on_landing",
            "fuel_used",
            "number_of_landings",
        ];

        const isFloatValueField = floatValueFields.includes(name);
        let newValue: string | number = value;

        if (isFloatValueField && value !== '') {
            newValue = parseFloat(value);
        }

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const errorMsg = validateEngineTime(
            formValues.engine_time_before_flight,
            formValues.engine_time_after_flight
        );
        if (errorMsg) {
            setValidationError(errorMsg);
            return;
        }

        // Copy formValues to avoid mutating state
        const submissionValues = { ...formValues };

        // Convert flight_date to ISO string if valid
        if (submissionValues.flight_date) {
            const date = new Date(submissionValues.flight_date);
            if (!isNaN(date.getTime())) {
                submissionValues.flight_date = date.toISOString();
            } else {
                setError("Invalid flight date");
                return;
            }
        }

        // Only add UUID and submittedAt if this is a new flight
        if (!submissionValues.uuid && !flightData) {
            submissionValues.uuid = uuidv4();
        }
        if (!flightData) {
            submissionValues.submittedAt = new Date().toISOString();
        }

        try {
            const token = sessionStorage.getItem('token');
            const response = await apiClient.post('/v1/addFlight', submissionValues, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(response.status);

            if (response.status === 200) {
                navigate('/dashboard/flights');
            } else {
                setError("Failed to add flight. Please try again.");
            }
        } catch (err: unknown) {
            setError("An error occurred while adding the flight.");
            console.error("An error occurred during the API call:", err);
        }
    };

    const handleCancel = () => {
        navigate('/dashboard/flights');
    };

    return (
        <main>
            <div className="space-y-10 divide-y divide-gray-900/10">
                <form onSubmit={handleSubmit}>
                    <GeneralFlightInfoSection
                        formValues={formValues}
                        pilots={pilots}
                        aircrafts={aircrafts}
                        selectedPilot={selectedPilot}
                        selectedAircraft={selectedAircraft}
                        setSelectedPilot={setSelectedPilot}
                        setSelectedAircraft={setSelectedAircraft}
                        handleChange={handleChange}
                        pilotQuery={pilotQuery}
                        setPilotQuery={setPilotQuery}
                        aircraftQuery={aircraftQuery}
                        setAircraftQuery={setAircraftQuery}
                    />
                    <EngineOilFuelSection
                        formValues={formValues}
                        handleChange={handleChange}
                        validationError={validationError}
                    />
                    <ExtraInformationSection
                        formValues={formValues}
                        handleChange={handleChange}
                    />
                    <div className="mt-6 flex items-center justify-end gap-x-6 gap-y-8 p-10">
                        <Button type="button" onClick={handleCancel} outline>
                            Cancel
                        </Button>
                        <Button type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </div>
        </main>
    );
};

export default AddFlightSection;
